import { Redirect, Switch } from "react-router-dom";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { CollectionPointPage } from "../../containers/CollectionPointPage";
import { CollectionPointsPage } from "../../containers/CollectionPointsPage";
import { CollectionYardDetailSettingsPage } from "../../containers/CollectionYardDetailSettingsPage";
import { CollectionYardsPage } from "../../containers/CollectionYardsPage";
import { CollectionYardsSettingsPage } from "../../containers/CollectionYardsSettingsPage";
import { ContainerDetailPage } from "../../containers/ContainerDetailPage";
import { GeneralSettingsPage } from "../../containers/GeneralSettingsPage";
import { HomePage } from "../../containers/HomePage";
import { InventorySheetPage } from "../../containers/InventorySheetPage/InventorySheetPage";
import { MunicipalityStatePage } from "../../containers/MunicipalityStatePage";
import { WagonEventCreatePage } from "../../containers/WagonEventCreatePage";
import { WagonEventDetailPage } from "../../containers/WagonEventDetailPage";
import { WagonsCalendarPage } from "../../containers/WagonsCalendarPage";
import { WagonsPage } from "../../containers/WagonsPage";
import { WasteCollectionEditPage } from "../../containers/WasteCollectionEditPage";
import { WeightHistoryPage } from "../../containers/WeightHistoryPage";
import {
  COLLECTION_POINT_PAGE_PATH,
  COLLECTION_POINTS_PAGE_PATH,
  COLLECTION_YARD_DETAIL_SETTINGS_PAGE,
  COLLECTION_YARDS_PAGE_PATH,
  COLLECTION_YARDS_SETTINGS_PAGE,
  CONTAINER_DETAIL_PAGE_PATH,
  CREATE_WAGON_EVENT_PAGE_PATH,
  DETAIL_WAGON_EVENT_PAGE_PATH,
  GENERAL_SETTINGS_PAGE,
  HOME_PAGE_PATH,
  INVENTORY_SHEET_PAGE_PATH,
  MUNICIPALITY_STATE_PAGE_PATH,
  WAGONS_CALENDAR_PAGE_PATH,
  WAGONS_PAGE_PATH,
  WASTE_COLLECTION_EDIT_PAGE_PATH,
  WEIGHT_HISTORY_PAGE_PATH,
} from "../../middleware/routes";
import { PrivateRoute } from "../PrivateRoute";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const AuthenticatedApp: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path={COLLECTION_POINTS_PAGE_PATH} component={CollectionPointsPage} />
      <PrivateRoute exact path={COLLECTION_POINT_PAGE_PATH} component={CollectionPointPage} />
      <PrivateRoute path={WEIGHT_HISTORY_PAGE_PATH} component={WeightHistoryPage} />
      <PrivateRoute exact path={WAGONS_CALENDAR_PAGE_PATH} component={WagonsCalendarPage} />
      <PrivateRoute path={CREATE_WAGON_EVENT_PAGE_PATH} component={WagonEventCreatePage} />
      <PrivateRoute path={DETAIL_WAGON_EVENT_PAGE_PATH} component={WagonEventDetailPage} />
      {/* not implemented for now */}
      {/* <PrivateRoute path="/collection-point-create" component={CollectionPointCreatePage} /> */}
      <PrivateRoute path={WAGONS_PAGE_PATH} component={WagonsPage} />
      <PrivateRoute path={INVENTORY_SHEET_PAGE_PATH} component={InventorySheetPage} />
      <PrivateRoute path={COLLECTION_YARDS_PAGE_PATH} component={CollectionYardsPage} />
      <PrivateRoute path={CONTAINER_DETAIL_PAGE_PATH} component={ContainerDetailPage} />
      <PrivateRoute path={MUNICIPALITY_STATE_PAGE_PATH} component={MunicipalityStatePage} />
      <PrivateRoute path={WASTE_COLLECTION_EDIT_PAGE_PATH} component={WasteCollectionEditPage} />
      <PrivateRoute path={GENERAL_SETTINGS_PAGE} component={GeneralSettingsPage} />
      <PrivateRoute path={COLLECTION_YARDS_SETTINGS_PAGE} component={CollectionYardsSettingsPage} />
      <PrivateRoute path={COLLECTION_YARD_DETAIL_SETTINGS_PAGE} component={CollectionYardDetailSettingsPage} />
      <PrivateRoute exact path={HOME_PAGE_PATH} component={HomePage} />
      <Redirect to={HOME_PAGE_PATH} />
    </Switch>
  );
};
