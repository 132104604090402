import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "../../../../../../components/Checkbox";
import { setFilter } from "../../../../../../reducers/wagonsReportStateReducer";
import { getWagonsReportListPlateNumberOptions } from "../../../../../../selectors/wagonsReport";
import { getWagonsReportFilterValue } from "../../../../../../selectors/wagonsReport";
import { RootState } from "../../../../../../store";
import { wagonReportTableColumnType } from "../../WagonsReportTableUtils";

interface Props {
  fieldName: wagonReportTableColumnType;
}

export const PlateNumberFilter: React.FC<Props> = ({ fieldName }) => {
  const dispatch = useDispatch();
  const options = useSelector(getWagonsReportListPlateNumberOptions);
  const value = [...(useSelector((state: RootState) => getWagonsReportFilterValue(state, fieldName))?.value ?? [])];

  return (
    <>
      {options.map((option) => (
        <div key={option}>
          <Checkbox
            checked={value.includes(option)}
            onChange={() => {
              if (!value.includes(option)) {
                value.push(option);
              } else {
                value.splice(value.indexOf(option), 1);
              }
              dispatch(
                setFilter({ id: fieldName, columnField: fieldName, operatorValue: "isAnyOf", value: [...value] })
              );
            }}
          />
          {option}
        </div>
      ))}
    </>
  );
};
