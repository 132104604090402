import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, SelectChangeEvent } from "@mui/material";

import { Container } from "../../components/Container";
import { CustomButton } from "../../components/CustomButton";
import { PageSettingsContainer } from "../../components/PageSettingsContainer";
import { SelectComponent } from "../../components/SelectComponent";
import { FixedTextInputSize, TextInput } from "../../components/TextInput";
import { HOME_PAGE_PATH } from "../../middleware/routes";
import {
  administrationNameMaxLength,
  updateAdministrationNameHungarianThunk,
  updateLanguageThunk,
} from "../../reducers/authReducer";
import { getAdministrationNameHungarian, getLanguage } from "../../selectors/auth";
import { ILanguageValueEnum } from "../../types/localization";
import { ISelectOption } from "../../types/select";
import { getInitialOption, languagesOptionsArray } from "../../utils/selectOptions";

import styles from "./GeneralSettingsPage.module.scss";

export const GeneralSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const dispatch = useDispatch();
  const history = useHistory();

  const initNameHungarian = useSelector(getAdministrationNameHungarian);
  const [nameHungarian, setNameHungarian] = useState<string>(initNameHungarian);

  const memoizedLanguagesOptionsArray = useMemo(() => languagesOptionsArray, [languagesOptionsArray]);

  const initLanguageOption = getInitialOption(memoizedLanguagesOptionsArray, language);
  const [selectedLanguageOption, setSelectedLanguageOption] = useState<ISelectOption>(initLanguageOption);

  const handleLanguageChange = (event: SelectChangeEvent<ILanguageValueEnum>): void => {
    const optValue = event.target.value as ILanguageValueEnum;
    const newSelectedOption = languagesOptionsArray.find(({ value }) => optValue === value) as ISelectOption;
    setSelectedLanguageOption(newSelectedOption);
  };

  const handleSave = () => {
    if (selectedLanguageOption.value !== initLanguageOption.value) {
      dispatch(updateLanguageThunk(selectedLanguageOption.value as ILanguageValueEnum));
    }

    if (nameHungarian !== initNameHungarian) {
      dispatch(updateAdministrationNameHungarianThunk(nameHungarian));
    }

    history.push(HOME_PAGE_PATH);
  };

  return (
    <PageSettingsContainer headerText={t("generalSettings")} fullHeight={false}>
      <Container>
        <div className={styles.wrapperItems}>
          <div className={styles.item}>
            <p>{t("selectedLanguage")}</p>
            <SelectComponent
              customCssClass="minWidth"
              handleChange={handleLanguageChange}
              value={selectedLanguageOption.value}
              optionsArray={languagesOptionsArray}
            />
          </div>
          <Divider />
          <div className={styles.item}>
            <div className={styles.wrapperText}>
              <p>{t("nameHu")}</p>
              <p className={styles.description}>{t("nameHuDescription")}</p>
            </div>
            <TextInput
              value={nameHungarian}
              placeHolder={t("nameHu")}
              onChange={(e) => setNameHungarian(e.target.value)}
              fixedSize={FixedTextInputSize.fixedMd}
              maxLength={administrationNameMaxLength}
            />
          </div>
        </div>
      </Container>
      <div className={styles.buttonContainer}>
        <CustomButton text={t("save")} onClick={handleSave} className={styles.saveBtn} />
      </div>
    </PageSettingsContainer>
  );
};
