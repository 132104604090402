import {
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterItem,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";

import { DataGridColumnType } from "../../../../utils/enums";

export enum wagonReportTableColumnType {
  date = "date",
  location = "location",
  containerType = "containerType",
  weight = "weight",
  rfid = "rfid",
  plateNumber = "plateNumber",
}

export const wagonReportTableColumnTypeMap = new Map<wagonReportTableColumnType, DataGridColumnType>([
  [wagonReportTableColumnType.location, DataGridColumnType.string],
  [wagonReportTableColumnType.date, DataGridColumnType.date],
  [wagonReportTableColumnType.rfid, DataGridColumnType.string],
  [wagonReportTableColumnType.weight, DataGridColumnType.number],
  [wagonReportTableColumnType.containerType, DataGridColumnType.number],
  [wagonReportTableColumnType.plateNumber, DataGridColumnType.string],
]);

const customDateOperators: GridFilterOperator[] = [
  {
    label: ">=",
    value: ">=",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }

      return (params): boolean => {
        return new Date(params.value) >= new Date(filterItem.value);
      };
    },
  },
  {
    label: "<=",
    value: "<=",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }

      return (params): boolean => {
        return new Date(params.value) <= new Date(filterItem.value);
      };
    },
  },
];

const customNumberOperators: GridFilterOperator[] = [
  ...getGridNumericOperators(),
  {
    label: "hasWarning",
    value: "hasWarning",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }

      return (params): boolean => {
        return params.row.warning === Boolean(filterItem.value);
      };
    },
  },
];

export const wagonReportTableColumnOperatorMap = new Map<DataGridColumnType, GridFilterOperator[]>([
  [DataGridColumnType.string, getGridStringOperators()],
  [DataGridColumnType.number, customNumberOperators],
  [DataGridColumnType.date, customDateOperators],
]);
