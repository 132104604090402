import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Status } from "../types/state";
import { wagonsMapFilter } from "../utils/mapFilter";

export const getWagonsReportState = (state: RootState) => state.wagonsReport;
export const getWagonsReportIsLoading = (state: RootState): boolean => state.wagonsReport.status !== Status.success;
export const getWagonsReportIsIdle = (state: RootState): boolean => state.wagonsReport.status === Status.idle;
export const getWagonsSummary = (state: RootState) => state.wagonsReport.summary;
export const getWagonsSummaryDefault = (state: RootState) => state.wagonsReport.summaryDefault;
export const getWagonsReportList = (state: RootState) => state.wagonsReport.report.list;
export const getWagonsReportListPlateNumberOptions = createSelector(getWagonsReportList, (list) =>
  list.reduce((acc: string[], item) => {
    if (item.plateNumber && !acc.includes(item.plateNumber)) {
      acc.push(item.plateNumber);
    }
    return acc;
  }, [])
);
export const getWagonsReportFilterModel = (state: RootState) => state.wagonsReport.filters.filter;
export const getWagonsReportFilterValue = (state: RootState, filterId: string) =>
  state.wagonsReport.filters.filter.items.find((item) => item.id === filterId);
export const getWagonsReportFilterIsActive = (state: RootState, fieldName: string) =>
  !!state.wagonsReport.filters.filter.items.find((item) => String(item.id).startsWith(fieldName));
export const getWagonsReportSortValue = (state: RootState, fieldName: string) =>
  fieldName === state.wagonsReport.filters.sort[0]?.field ? state.wagonsReport.filters.sort[0]?.sort : null;
export const getWagonsReportSortModel = (state: RootState) => state.wagonsReport.filters.sort;
export const getWagonsMap = (state: RootState) =>
  wagonsMapFilter(state.wagonsReport.map, state.wagonsReport.mapFilters);
export const isMapViewActive = (state: RootState) => state.wagonsReport.mapView;
export const getJumpTo = (state: RootState) => state.wagonsReport.jumpTo;
export const getWagonsMapFilters = (state: RootState) => state.wagonsReport.mapFilters;
export const getWagonsReportScrollTop = (state: RootState) => state.wagonsReport.scrollTop;
