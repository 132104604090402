export enum ColumnType {
  created = "created",
  locationName = "locationName",
  wcpid = "wcpid",
  collectionYardName = "collectionYardName",
  wasteTypes = "wasteTypes",
  wasteSum = "wasteSum",
  priceSum = "priceSum",
  paid = "paid",
}

export const columnLabels = Object.values(ColumnType);
