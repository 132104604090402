import { RootState } from "../store";

export const getUserStatus = (state: RootState) => state.auth.status;

export const getUserErrorMsg = (state: RootState) => state.auth.errorMsg;

export const getUser = (state: RootState) => state.auth.user;

export const getAdministrationName = (state: RootState) => state.auth.user?.administrationName;
export const getAdministrationNameHungarian = (state: RootState) => state.auth.user.administrationNameHungarian;

export const getJwtFromStore = (state: RootState) => state.auth.jwt;

export const getLanguage = (state: RootState) => state.auth.user.language;
