import { ILanguageValueEnum } from "../types/localization";
import { ISelectOption } from "../types/select";
import { Answer, NotificationsType, WagonEventPeriodicity, WasteType } from "./enums";

export const wasteTypesOptionsArray: ISelectOption[] = [
  {
    value: WasteType.mixed,
    label: `enums.wastetypes.${WasteType.mixed}`,
  },
  {
    value: WasteType.paper,
    label: `enums.wastetypes.${WasteType.paper}`,
  },
  {
    value: WasteType.glass,
    label: `enums.wastetypes.${WasteType.glass}`,
  },
  {
    value: WasteType.plastics,
    label: `enums.wastetypes.${WasteType.plastics}`,
  },
  {
    value: WasteType.metal,
    label: `enums.wastetypes.${WasteType.metal}`,
  },
  {
    value: WasteType.bio,
    label: `enums.wastetypes.${WasteType.bio}`,
  },
  {
    value: WasteType.vkm,
    label: `enums.wastetypes.${WasteType.vkm}`,
  },
];

export const periodicalOptionsArray: ISelectOption[] = [
  {
    value: Answer.yes,
    label: `enums.answers.${Answer.yes}`,
  },
  {
    value: Answer.no,
    label: `enums.answers.${Answer.no}`,
  },
];

export const periodicityOptionsArray: ISelectOption[] = [
  {
    value: WagonEventPeriodicity.sevenDays,
    label: `enums.wagonEventPeriodicity.${WagonEventPeriodicity.sevenDays}`,
  },
  {
    value: WagonEventPeriodicity.fourteenDays,
    label: `enums.wagonEventPeriodicity.${WagonEventPeriodicity.fourteenDays}`,
  },
  {
    value: WagonEventPeriodicity.twentyOneDays,
    label: `enums.wagonEventPeriodicity.${WagonEventPeriodicity.twentyOneDays}`,
  },
  {
    value: WagonEventPeriodicity.twentyEightDays,
    label: `enums.wagonEventPeriodicity.${WagonEventPeriodicity.twentyEightDays}`,
  },
];

export const languagesOptionsArray: ISelectOption[] = [
  {
    value: ILanguageValueEnum.sk,
    label: "slovak",
  },
  {
    value: ILanguageValueEnum.de,
    label: "german",
  },
  {
    value: ILanguageValueEnum.cs,
    label: "czech",
  },
  {
    value: ILanguageValueEnum.hu,
    label: "hungarian",
  },
];

export const notificationsTypeOptionsArray: ISelectOption[] = [
  {
    value: NotificationsType.holidays,
    label: `enums.notificationTypes.${NotificationsType.holidays}`,
  },
  {
    value: NotificationsType.canceled,
    label: `enums.notificationTypes.${NotificationsType.canceled}`,
  },
];

export const getInitialOption = (optionsArray: ISelectOption[], value: string): ISelectOption => {
  let initialOption = optionsArray[0];

  const thisItem = optionsArray.find((item) => item.value === value);
  if (thisItem) {
    initialOption = thisItem;
  }

  return initialOption;
};
