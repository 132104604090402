export const isDisabled = (disableTime: string | null, currentYear: number): boolean => {
  if (!disableTime) return false;

  const disableYear = new Date(disableTime).getFullYear();
  return currentYear >= disableYear;
};

export const isActiveYear = (enableTime: string | null, disableTime: string | null, currentYear: number): boolean => {
  let isActiveInDisabledTime = true;
  let isActiveInEnabledTime = true;

  if (disableTime) {
    const disableYear = new Date(disableTime).getFullYear();
    isActiveInDisabledTime = currentYear < disableYear;
  }

  if (enableTime) {
    const enableYear = new Date(enableTime).getFullYear();
    isActiveInEnabledTime = currentYear >= enableYear;
  }

  return isActiveInDisabledTime && isActiveInEnabledTime;
};
