import "./index.scss";

import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import { App } from "./components/App";
import translationCS from "./locales/cs.json";
import translationDE from "./locales/de.json";
import translationHU from "./locales/hu.json";
import translationSK from "./locales/sk.json";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { setInterceptors } from "./utils/axiosRequest";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? "");

setInterceptors(store);

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    resources: {
      sk: {
        translation: translationSK,
      },
      cs: {
        translation: translationCS,
      },
      de: {
        translation: translationDE,
      },
      hu: {
        translation: translationHU,
      },
    },
    load: "all",
    fallbackLng: "sk",
    react: {
      useSuspense: false,
    },
  })
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
