import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import { COLLECTION_YARDS_SETTINGS_PAGE, GENERAL_SETTINGS_PAGE } from "../../middleware/routes";
import { getCollectionYardsList } from "../../selectors/collectionYardsSettings";
import { PageContainer, PageContainerProps } from "../PageContainer";

import styles from "./PageSettingsContainer.module.scss";

export const PageSettingsContainer: React.FC<PageContainerProps> = ({ children, ...rest }) => {
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const listOfCollectionYard = useSelector(getCollectionYardsList);

  const items = [{ name: t("generalSettings"), path: GENERAL_SETTINGS_PAGE }];

  if (listOfCollectionYard.length > 0) {
    items.push({ name: t("collectionYard"), path: COLLECTION_YARDS_SETTINGS_PAGE });
  }

  return (
    <PageContainer {...rest}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperNavigation}>
          {items.map((item) => {
            const itemPathWithoutPlural = item.path.slice(0, -2);

            return (
              <Link
                key={item.name}
                to={item.path}
                className={classNames(styles.navigationItem, {
                  [styles.active]: pathname.includes(itemPathWithoutPlural),
                })}
              >
                {item.name}
              </Link>
            );
          })}
        </div>
        <div>{children}</div>
      </div>
    </PageContainer>
  );
};
