import { useTranslation } from "react-i18next";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { TableAllWastesCell } from "../../../components/TableAllWastesCell";
import { TableDateCell } from "../../../components/TableDateCell";
import { TablePaymentStatusIndicatorCell } from "../../../components/TablePaymentStatusIndicatorCell";
import { TablePriceCell } from "../../../components/TablePriceCell";
import { TableWeightCell } from "../../../components/TableWeightCell";
import { IColumnAlignObject, IColumnValuePair } from "../../../types/table";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";
import { columnLabels, ColumnType } from "./CollectionYardsTableColumnTypes";
import { CollectionYardsTableHeaderCell } from "./CollectionYardsTableHeaderCell";

const columnAlignObject: IColumnAlignObject<ColumnType> = {
  center: [ColumnType.paid],
};

const columnFlexValuePairs: IColumnValuePair<ColumnType>[] = [
  {
    name: ColumnType.created,
    value: 2,
  },
  {
    name: ColumnType.locationName,
    value: 2,
  },
  {
    name: ColumnType.wcpid,
    value: 2,
  },
  {
    name: ColumnType.collectionYardName,
    value: 3,
  },
  {
    name: ColumnType.wasteTypes,
    value: 3,
  },
  {
    name: ColumnType.priceSum,
    value: 2,
  },
  {
    name: ColumnType.wasteSum,
    value: 2,
  },
  {
    name: ColumnType.paid,
    value: 2,
  },
];

const columnMinWidthValuePairs: IColumnValuePair<ColumnType>[] = [];

export const useGetCollectionYardsTableColumns = () => {
  const { t } = useTranslation();
  const { getAlign, getFlex, getMinWidth } = getTableColumnUtils();

  const columns: GridColDef[] = columnLabels.map((label) => {
    const columnObject: GridColDef = {
      field: label,
      headerName: t(label),
      type: "string",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <CollectionYardsTableHeaderCell params={params} />;
      },
      flex: getFlex(label, columnFlexValuePairs),
      align: getAlign(label, columnAlignObject),
      headerAlign: getAlign(label, columnAlignObject),
      minWidth: getMinWidth(label, columnMinWidthValuePairs),
    };

    columnObject.renderCell = (params: GridRenderCellParams) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case ColumnType.created:
          return <TableDateCell params={params} />;
        case ColumnType.wasteTypes:
          return <TableAllWastesCell params={params} />;
        case ColumnType.priceSum:
          return <TablePriceCell params={params} />;
        case ColumnType.wasteSum:
          return <TableWeightCell params={params} />;
        case ColumnType.paid:
          return <TablePaymentStatusIndicatorCell params={params} withPaidText />;
        default:
          break;
      }
    };

    return columnObject;
  });

  return columns;
};
